<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch">

    <MemberEditorCompactModal
      ref="member-editor-compact-modal-tab"
      class="mt-8"
      :member_id="member_id"

      @created="member_created"
      @updated="member_updated"
      @deleted="member_deleted"
    />

    <div class="card-body pt-0 table-responsive" style="overflow: visible;">

      <b-row>
        <b-col xl="3" md="3" sm="3">
          <b-form-group
            id="input-group-created_from"
            :label="$t('PAGES.RENEWS.CREATED_FROM')"
            label-for="input-created_from"
          >
            <memlist-date-picker v-model="filters.created_from"></memlist-date-picker>
          </b-form-group>
        </b-col>
        <b-col xl="3" md="3" sm="3">
          <b-form-group
            id="input-group-created_to"
            :label="$t('PAGES.RENEWS.CREATED_TO')"
            label-for="input-created_to"
          >
            <memlist-date-picker v-model="filters.created_to"></memlist-date-picker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="3" md="3" sm="3">
          <b-form-group
            :label="$t('SUBSCRIPTION.PAYMENT_METHOD')"
            >
            <b-form-select
              id="status-filter"
              class="mr-sm-2 mx-0"
              v-model="filters.payment_method"
              :options="payment_method_options"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col xl="3" md="3" sm="3">
          <b-form-group
            :label="$t('SUBSCRIPTION.STATUS')"
            >
          <b-form-select
            id="status-filter"
            class="mx-0"
            v-model="filters.statuse"
            :options="statuses_options"

          />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col lg="6" sm="12">
          <b-form-input
            id="filter-input"
            v-model="filters.text"
            type="search"
            style="max-width:621px"
            :placeholder="$t('COMMON.SEARCH')"
          ></b-form-input>
        </b-col>
        <b-col lg="6" sm="12">
          <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100">

            <b-form-select
              class="mr-sm-2 mt-2 align-bottom"
              style="max-width: 100px;"
              :options="[100, 500, 1000]"
              v-model="per_page"
            />

            <b-pagination
              class="mb-0 mt-2 align-bottom"
              v-model="current_page"
              :total-rows="totalRows"
              :per-page="per_page"
              aria-controls="invoice-table"
              first-number
              last-number
            ></b-pagination>
          </div>
        </b-col>
      </b-row>

      <b-table
        id="subscriptions-table"
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        :fields="headers"
        :filter="filters"
        :items="ajax_pagination"
        :per-page="per_page"
        :current-page="current_page"
        head-variant="light"
        ref="subscriptionsTable"
        @filtered="on_filtered"
        @sort-changed="on_sort_changed"
      >
        <template #cell(payment_status)="row">
          <v-chip
            style="font-size: 10px; color: red; border-color: red;"
            v-if="row.item.payment_status === 'NOT_PAID'"
            color="red"
            outlined
          >
            {{ $t('INVOICE.PAYMENT_STATUSES.NOT_PAID') }}
          </v-chip>

          <v-chip
            style="font-size: 10px; color: green; border-color: green;"
            v-else-if="row.item.payment_status === 'PAID'"
            color="green"
            outlined
          >
            {{ $t('INVOICE.PAYMENT_STATUSES.PAID_IN_FULL') }}
          </v-chip>

          <v-chip
            style="font-size: 10px;"
            v-else
            color="blue"
            outlined
          >
            {{ $t('INVOICE.PAYMENT_STATUSES.' + row.item.payment_status) }}
          </v-chip>
        </template>

        <template #cell(printed)="row">
          <v-chip
            style="font-size: 10px; color: green; border-color: green;"
            v-if="row.item.printed"
            color="red"
            outlined
          >
            {{ $t('COMMON.YES') }}
          </v-chip>

          <v-chip
            style="font-size: 10px; color: red; border-color: red;"
            v-else
            color="red"
            outlined
          >
            {{ $t('COMMON.NO') }}
          </v-chip>

        </template>


        <template #cell(actions)="row">
          <div class="justify-content-end d-flex">
            <a v-if="row.item.member" class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="member_selected(row.item.member.member_id)">
              <span class="svg-icon svg-icon-md text-primary">
                <i class="fa fa-user color-primary"></i>
              </span>
            </a>
            
          </div>
        </template>

      </b-table>
      <b-row class="mt-8">
        <b-col><span>{{ $t('INVOICE.NUM_ROWS') }}: {{total_rows}}</span></b-col>
      </b-row>
      <!--end::Table-->
    </div>

    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>


<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'SubscriptionPaymentsTable',
  emits: [],
  components: {
    
  },
  watch: {

  },
  mixins: [ toasts ],
  methods: {
    member_selected(member_id) {
      this.member_id = member_id;
      this.$refs['member-editor-compact-modal-tab'].show(member_id);
    },
    select_payment(item) {},
    member_updated(member) {},
    
    on_sort_changed(sortChanged) {

      if (sortChanged.sortBy === '') {
        this.orderBy = undefined;
      }
      else {
        this.orderBy = {

        };

        this.orderBy[sortChanged.sortBy] = sortChanged.sortDesc ? 'desc' : 'asc';
      }
    },

    on_filtered(userFilteredOrders) {

    },

    
    get_options() {

      const options = {

      };


      if (this.filters.created_from) {
        options.created_from = this.filters.created_from;
      }

      if (this.filters.created_from) {
        options.created_from = this.filters.created_from;
      }

      if (this.filters.created_to) {
        options.created_to = this.filters.created_to;
      }

      if (this.filters.payment_method) {
        options.payment_methods = [this.filters.payment_method];
      }

      if (this.filters.status) {
        options.statuses = [this.filters.status];
      }

      if (this.filters.text !== '') {
        options.text = this.filters.text;
      }

      return options;
      },

      // items are fetched here whenever pagination ahas changed or
      // by forcing reload via: this.$refs['orderTable'].refresh();
    ajax_pagination: function(ctx, callback) {
      const vm = this;
      let params = {
        pageNo: ctx.current_page,
        pageSize: ctx.per_page,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_page_count();

      const options = this.get_options();


      axios
        .post(`/shop_order/subscriptions/pagination`, {
          options: options,
          orderBy: this.orderBy,
          page: this.current_page,
          limit: this.per_page
        })
        .then(res => {
          var arr = res.data;

          this.copy_rows = arr;

          callback(arr || []);
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_GET_DATA'));
          console.error('pagination get error', err);
        });

      return null;
    },

    get_page_count() {

      const options = this.get_options();

      axios
        .post(`/shop_order/subscriptions/pagination/count?limit=${this.per_page}&page=${this.current_page}`, { options: options })
        .then(res => {
          this.totalRows = res.data.count;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.UNABLE_GET_NUM_ORDERS'));
        });
      },
  },
  computed: {
    payment_method_options() {
      return [ 
        { text: this.$t('SUBSCRIPTION.SWISH'), value: 'SWISH' },
        { text: this.$t('SUBSCRIPTION.STRIPE'), value: 'STRIPE' },
        { text: this.$t('SUBSCRIPTION.AUTOGIRO'), value: 'AUTOGIRO' },
      ]
    },
  },
  data() {
    return {
      
      per_page: 25,
      current_page: 1,
      selected_item: null,
      member_id: null,
      copy_rows: [],
      selected_shop_order_ids: [],
      statuses: [],
      statuses_options: [
        { value: 'PENDING', text: this.$t('SUBSCRIPTION.PENDING') },
        { value: 'CAPTURED', text: this.$t('SUBSCRIPTION.CAPTURED') },
        { value: 'CANCELLED', text: this.$t('SUBSCRIPTION.CANCELLED') },
        { value: 'FAILED', text: this.$t('SUBSCRIPTION.FAILED') },
        { value: 'RETRY', text: this.$t('SUBSCRIPTION.RETRY') },
      ],
      orderBy: { created_at: 'desc' },
      per_page: 100,
      current_page: 1,
      totalRows: 0,
      filters: {
        textFilter: '',
        created_from: null,
        created_to: null,
        statuses: []
      },
      headers: [
        {
          key: 'selected',
          label: '',
          sortable: false,
          tdClass: 'td-short',
          thClass: 'td-short'
        },
        {
          key: 'member.member_id',
          label: this.$t('COMMON.MEMBER_PERSON'),
          sortable: false,
          formatter: (_, __, item) => {
            if (item.member) {
              if (item.member.reg) {
                return this.$t('COMMON.WAITING');
              }
              return '(M) ' + item.member.firstname + ' ' + item.member.lastname;
            }
            else if (item.person) {
              return '(P) ' + item.person.firstname + ' ' + item.person.lastname;
            }
            return '-';
          },
          tdClass: 'td-overflow'
        },
        {
          key: 'shop_order_id',
          label: 'Ref',
          sortable: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'created_at',
          label:  this.$t('COMMON.CREATED'),
          sortable: true,
          tdClass: 'td-short4',
          thClass: 'td-short4'
        },
        {
          key: 'shop_order_items',
          label: this.$t('PAGES.SHOPITEM.ARTICLE'),
          sortable: false,
          formatter: (_, __, item) => {
            return item.shop_order_items.length > 0 ? item.shop_order_items[0].shop_item.name : '-';
          },
          tdClass: 'td-overflow'
        },
        {
          key: 'is_paid',
          label:  this.$t('COMMON.PAID_SHORT'),
          formatter: (_, __, item) => {
            return item.is_paid ?  this.$t('COMMON.YES') : this.$t('COMMON.NO');
          },
          sortByFormatted: true,
          filterByFormatted: true,
          sortable: true,
          tdClass: 'td-short3',
          thClass: 'td-short3'
        },
        {
          key: 'amount',
          label: this.$t('COMMON.TOTAL'),
          sortable: true,
          tdClass: 'td-short3',
          thClass: 'td-short3'
        },
        {
          key: 'is_settled',
          label:  this.$t('COMMON.SETTLED'),
          formatter: (_, __, item) => {
            return item.is_settled ? this.$t('COMMON.YES') :  this.$t('COMMON.NO');
          },
          sortByFormatted: true,
          filterByFormatted: true,
          sortable: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'actions',
          label: ''
        }
      ],
    

      showFields: [
        { key: 'shop_item_id', label: this.$t('COMMON.ID'), show: true, sortable: true, thClass: 'td-short', tdClass: ''},
        { key: 'created_at', label: this.$t('PAGES.SHOPITEM.CREATED_AT'), show: true, sortable: true, thClass: '', tdClass: ''},
        { key: 'name', label: this.$t('PAGES.SHOPITEM.NAME'), show: true, sortable: true, thClass: 'td-short', tdClass: ''},
        { key: 'error', label: this.$t('SHOP_ITEM.ERROR'), show: true, sortable: true, thClass: 'td-short2', tdClass: '' },
        { key: 'is_active', label: this.$t('SHOP_ITEM.IS_ACTIVE'), show: true, sortable: true, thClass: 'td-short2', tdClass: '' },
        {
          key: 'next_shop_item_id',
          label: this.$t('PAGES.SHOPITEM.NEXT_SHOP_ITEM'),
          show: true,
          sortable: true,
          thClass: 'pl-7 w-100',
          tdClass: 'pl-7',
          formatter: (_, __, item) => {
            return item.next_shop_item ? item.next_shop_item.name : '';
          },
        },
        { key: 'amount', label: this.$t('SHOP_ITEM.AMOUNT'), show: true, sortable: true, thClass: '', tdClass: ''},
        { key: 'amount_vat', label: this.$t('SHOP_ITEM.AMOUNT_VAT'), show: true, sortable: true, thClass: '', tdClass: ''},
        { key: 'actions', label: '', show: true, sortable: false}
      ],
      filter: '',
      list: [

      ]
    };
  }
};
</script>


<style lang="css" scoped>



</style>
